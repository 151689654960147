import React, { useEffect } from "react";

const HangfireDashboard: React.FC = () => {
  useEffect(() => {
    // Get token from localStorage and set it in cookies
    const token = localStorage.getItem("token");
    if (token) {
      document.cookie = `Authorization=${token}; path=/; domain=${process.env.REACT_APP_HANGFIRE_END_POINT}; SameSite=None; Secure`;
    }
  }, []);

  return (
      <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
        <iframe
            src={`${process.env.REACT_APP_HANGFIRE_END_POINT}/hf/hfdb`}
            title="Hangfire Dashboard"
            style={{ width: "100%", height: "100%", border: "none" }}
        />
      </div>
  );
};

export default HangfireDashboard;