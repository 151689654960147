import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import React from "react";
import {useSelector} from "react-redux";

import {getRepLevel, getRepType, hasPermission} from "../../../../../utils/helpers";
import {Skill, SkillLevel} from "../../../../workers/types";
import { MIN_CROSS_SKILL_ROW_HEIGHT } from "../../../constants";
import SkillRepresentationCell from "./column-skill-representations-cell";

interface Params {
    row: {
        skills: Skill[];
        skillInterests?: Skill[];
        [key: string]: any;
    };
}

interface ColumnSkillCellProps {
    params: Params;
    skill: Skill;
    handleSkillLevels: (
        params: Params,
        skill: Skill,
        level: SkillLevel,
        setState: React.Dispatch<React.SetStateAction<Skill | null>>,
        state: Skill | null
    ) => void;
    allSkillLevels: SkillLevel[];
    handleAddSkillInterested: (
        params: Params,
        skill: Skill,
        setState: React.Dispatch<React.SetStateAction<Skill | null>>
    ) => void;
    skillsUpdatedListInSession: Skill[];
}

const ColumnSkillCell: React.FC<ColumnSkillCellProps> = ({
                                                             params,
                                                             skill,
                                                             handleSkillLevels,
                                                             allSkillLevels,
                                                             handleAddSkillInterested,
                                                             skillsUpdatedListInSession,
                                                         }) => {
    const skillId = skill.skillId ?? skill.id;
    const workerSkill = params.row.skills.find((x) => parseInt(String(x.skillId)) === parseInt(String(skillId)));

    const isInterested = params.row.skillInterests
        ? params.row.skillInterests.find((x) => parseInt(String(x.skillId)) === parseInt(String(skillId)))
        : false;

    const [state, setState] = useState<Skill | null>(workerSkill || null);
    const [workerSkillsAnchorEl, setWorkerSkillsAnchorEl] = useState<null | HTMLElement>(null);
    const [otherSkillsAnchorEl, setOtherSkillsAnchorEl] = useState<null | HTMLElement>(null);
    const openWorkerSkills = Boolean(workerSkillsAnchorEl);
    const openOtherSkills = Boolean(otherSkillsAnchorEl);

    const userRolesWithPermission = useSelector((state: any) => state.user.userData.userRolesWithPermissions);

    const handleWorkerSkillsClick = (event: React.MouseEvent<HTMLElement>) => {
        if (hasPermission(userRolesWithPermission, "CROSS_SKILLING.UPDATE_SKILL_LEVEL")) {
            setWorkerSkillsAnchorEl(event.currentTarget);
        }
    };

    const handleWorkerSkillsClose = () => {
        setWorkerSkillsAnchorEl(null);
    };

    const updateSkillLevel = useCallback(
        (params: Params, skill: Skill, level: SkillLevel, state: Skill | null, handleClose: () => void) => {
            handleSkillLevels(params, skill, level, setState, state);
            handleClose();
        },
        [handleSkillLevels]
    );

    const skillLevelMenu = useMemo(
        () => (
            <Menu
                id="worker-skills-menu"
                anchorEl={workerSkillsAnchorEl}
                open={openWorkerSkills}
                onClose={handleWorkerSkillsClose}
                MenuListProps={{
                    "aria-labelledby": "worker-skills-button",
                }}
            >
                {allSkillLevels.map((level) => (
                    <MenuItem
                        value={level.repCode}
                        key={level.id}
                        onClick={() =>
                            updateSkillLevel(params, skill, level, state, handleWorkerSkillsClose)
                        }
                    >
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <SkillRepresentationCell
                                level={level.level}
                                value={level.value}
                                backgroundColor={level.backColorCode}
                                foregroundColor={level.foreColorCode}
                                representationType={getRepType(level.repCode)}
                                representationLevel={getRepLevel(level.repCode, level.level)}
                                border={1}
                                backgroundWidth="35px"
                                backgroundHeight="35px"
                            />
                            <Typography ml={1}>{level.name}</Typography>
                        </Box>
                    </MenuItem>
                ))}
            </Menu>
        ),
        [workerSkillsAnchorEl, openWorkerSkills, allSkillLevels, updateSkillLevel, params, skill, state]
    );

    if (!state && isInterested) {
        return (
            <Box
                sx={{
                    borderRadius: "3px",
                    height: MIN_CROSS_SKILL_ROW_HEIGHT,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    width: "100%",
                }}
                key={`${skill.skillId}-interested`}
                onClick={() => handleAddSkillInterested(params, skill, setState)}
            >
                <Typography textAlign="center" sx={{ fontSize: "12px", color: "#49454F" }}>
                    Interested
                </Typography>
            </Box>
        );
    }

    //when the skill level is changed for one job, update other jobs with the same skill level
    const existingSkillUpdated = skillsUpdatedListInSession.find((x)=> x.workerId === params.row.id && x.skillId === skill.skillId);

    if (existingSkillUpdated) {
        return (
            <>
                <Box
                    aria-controls={openWorkerSkills ? "worker-skills-menu" : undefined}
                    aria-haspopup='true'
                    aria-expanded={openWorkerSkills ? "true" : undefined}
                    onClick={handleWorkerSkillsClick}
                    sx={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <SkillRepresentationCell
                            representationType={getRepType(existingSkillUpdated.repCode)}
                            representationLevel={getRepLevel(existingSkillUpdated.repCode, existingSkillUpdated.level)}
                            value={existingSkillUpdated.value}
                            level={existingSkillUpdated.level}
                            backgroundColor={existingSkillUpdated.backColorCode}
                            foregroundColor={existingSkillUpdated.foreColorCode}
                            height={20}
                            width={20}
                            backgroundWidth={"100%"}
                            backgroundHeight={"100%"}
                        />
                    </Box>
                </Box>
                {skillLevelMenu}
            </>
        );
    }

    if (!state)
        return (
            <>
                <Box
                    aria-controls={openOtherSkills ? "other-skills-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openOtherSkills ? "true" : undefined}
                    onClick={handleWorkerSkillsClick}
                    sx={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            height: MIN_CROSS_SKILL_ROW_HEIGHT,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "14px",
                                textTransform: "uppercase",
                            }}
                        >
                            -
                        </Typography>
                    </Box>
                </Box>
                {skillLevelMenu}
            </>
        );
    else {
        return (
            <>
                <Box
                    aria-controls={openWorkerSkills ? "worker-skills-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openWorkerSkills ? "true" : undefined}
                    onClick={handleWorkerSkillsClick}
                    sx={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <SkillRepresentationCell
                            representationType={getRepType(state.repCode)}
                            representationLevel={getRepLevel(state.repCode, state.level)}
                            value={state.value}
                            level={state.level}
                            backgroundColor={state.backColorCode}
                            foregroundColor={state.foreColorCode}
                            height={20}
                            width={20}
                            backgroundWidth="100%"
                            backgroundHeight="100%"
                        />
                    </Box>
                </Box>
                {skillLevelMenu}
            </>
        );
    }
};

export default ColumnSkillCell;
