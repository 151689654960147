import React from "react";

import {
  ColumnSkillCell,
  ColumnSkillHeader,
} from "../../../../../export/cross-skilling";

export const createSkillColumn = (
  arr,
  handleSkillLevels,
  allSkillLevels,
  handleAddSkillInterested,
  isJobFilter,
  searchText,
  isColumnGroup,
  isOther,
  skillsUpdatedListInSession
) => {
  const result = arr.map((skill) => {
    return {
      field: isOther
        ? skill.skillId.toString()
        : !isColumnGroup
        ? skill.skillId.toString()
        : `${skill.skillId}-${skill.jobId}`,

      headerName: skill.name,
      width: 100,
      type: "actions",
      headerAlign: "center",
      sortable: true,
      renderCell: (params) => (
        <ColumnSkillCell
          skill={skill}
          params={params}
          handleSkillLevels={handleSkillLevels}
          allSkillLevels={allSkillLevels}
          handleAddSkillInterested={handleAddSkillInterested}
          skillsUpdatedListInSession={skillsUpdatedListInSession}
        />
      ),
      renderHeader: () => (
        <ColumnSkillHeader
          skill={skill}
          isJobFilter={isJobFilter}
          searchText={searchText}
        />
      ),
      valueGetter: (params) => {
        // For sorting skills
        const skillInCell = params.row.skills.find(
          (x) => x.skillId === parseInt(skill.skillId)
        );
        if (skillInCell) {
          return skillInCell.level + 2;
        } else {
          return 1;
        }
      },
    };
  });
  return result;
};

export const createSkillColumnWithoutJobs = (
  skillsWithNoJobs,
  handleSkillLevels,
  allSkillLevels,
  handleAddSkillInterested,
  isJobFilter,
  searchText,
  skillsUpdatedListInSession,
  isSkillPriorityFilter,
  skillPriorityIds
) => {
  // Filter out other skills based selected priority ids
  const finalSkillsWithNoJobs = isSkillPriorityFilter
    ? skillsWithNoJobs.filter((skill) =>
        skillPriorityIds.includes(skill.priorityId)
      )
    : skillsWithNoJobs;
  const result = finalSkillsWithNoJobs.map((skill) => {
    return {
      field: `${skill.id}-${skill.jobId}`,
      headerName: skill.skillName,
      width: 100,
      headerAlign: "center",
      sortable: true,
      renderCell: (params) => (
        <ColumnSkillCell
          skill={skill}
          params={params}
          handleSkillLevels={handleSkillLevels}
          allSkillLevels={allSkillLevels}
          handleAddSkillInterested={handleAddSkillInterested}
          skillsUpdatedListInSession={skillsUpdatedListInSession}
        />
      ),
      renderHeader: () => (
        <ColumnSkillHeader
          skill={skill}
          isJobFilter={isJobFilter}
          searchText={searchText}
        />
      ),
      valueGetter: (params) => params.row[`${skill.id}-${skill.jobId}`],
    };
  });
  return result;
};

export const findOtherSkills = (arr1, arr2, propertyName = "skillId") => {
  const nonCommonObjectsArr1 = arr1.filter(
    (obj1) => !arr2.some((obj2) => obj1[propertyName] === obj2[propertyName])
  );

  const nonCommonObjectsArr2 = arr2.filter(
    (obj2) => !arr1.some((obj1) => obj2[propertyName] === obj1[propertyName])
  );

  const res = nonCommonObjectsArr1.concat(nonCommonObjectsArr2);
  return res.map((obj) => {
    return {
      ...obj,
      jobColor: "#F30909",
    };
  });
};

export const skillsWithoutJobs = (allSkills, allJobs) => {
  let allSkillsWithJobs = [];
  if (allJobs && allJobs.length > 0) {
    allJobs.forEach((job) => {
      job.skills.forEach((skill) => {
        if (
          !allSkillsWithJobs.find(
            (x) => parseInt(x.skillId) === parseInt(skill.skillId)
          )
        )
          allSkillsWithJobs.push(skill);
      });
    });
  }
  let skillsWithoutJobs = [];
  if (allSkills && allSkills.length > 0) {
    allSkills.forEach((skill) => {
      if (
        !allSkillsWithJobs.find(
          (x) => parseInt(x.skillId) === parseInt(skill.id)
        )
      )
        skillsWithoutJobs.push({
          ...skill,
          skillId: skill.id,
          jobId: -1,
          jobName: "Other Skills",
        });
    });
  }
  return skillsWithoutJobs;
};
