import React from "react";
import { useSelector } from "react-redux";

const useWebWorkerData = () => {
	const upcomingShifts = useSelector(
		(state: any) => state.worker.upcomingShifts
	);
	const location = useSelector((state: any) => state.worker.location);
	const shiftsConfirmed = useSelector(
		(state: any) => state.worker.shiftsConfirmed
	);

	const confirmShiftStatus = useSelector(
		(state: any) => state.worker.confirmShift.status
	);

	const shiftId = useSelector(
		(state: any) => state.worker.viewShiftDialog.shiftId
	);

	const allShiftsConfirmed = useSelector(
		(state: any) => state.worker.shiftsConfirmed
	);
	const reduxCancelShift = useSelector(
		(state: any) => state.worker.cancelShift
	);
	const skillLevels = useSelector((state: any) => state.worker.skillLevels);

	const confirmedShiftsErrorMessage = useSelector(
		(state: any) => state.worker.getConfirmedShifts.errorMessage
	);
	const getUpcomingShiftsErrorMessage = useSelector(
		(state: any) => state.worker.getUpcomingShifts.errorMessage
	);

	const signupShiftId = useSelector(
		(state: any) => state.worker.signupShiftDialog.shiftId
	);

	const allUpcomingShifts = useSelector(
		(state: any) => state.worker.upcomingShifts
	);

	const confirmShiftErrorMessage = useSelector(
		(state: any) => state.worker.confirmShift.errorMessage
	);

	const declineShiftStatus = useSelector(
		(state: any) => state.worker.declineShift.status
	);

	const declineShiftErrorMessage = useSelector(
		(state: any) => state.worker.declineShift.errorMessage
	);

	const confirmedShifts = useSelector(
		(state: any) => state.worker.shiftsConfirmed
	);

	const cancelShiftStatus = useSelector(
		(state: any) => state.worker.cancelShift.status
	);

	const confirmedShiftsStatus = useSelector(
		(state: any) => state.worker.getConfirmedShifts.status
	);

	const isViewShiftsDialogOpen = useSelector(
		(state: any) => state.worker.viewShiftDialog.isOpen
	);

	const isViewRegularShiftsDialogOpen = useSelector(
		(state: any) => state.worker.viewRegularShiftDialog.isOpen
	);

	const upcomingShiftsStatus = useSelector(
		(state: any) => state.worker.getUpcomingShifts.status
	);
	const isSignupShiftDialogOpen = useSelector(
		(state: any) => state.worker.signupShiftDialog.isOpen
	);

	const isCancelShiftPageOpen = useSelector(
		(state: any) => state.worker.isCancelShiftPageOpen
	);

	const isAcknowledgementPageOpen = useSelector(
		(state: any) => state.worker.isAcknowledgementPageOpen
	);

	const getPasscodeStatus = useSelector(
		(state: any) => state.worker.getPasscode.status
	);

	const getPasscodeError = useSelector(
		(state: any) => state.worker.getPasscode.errorMessage
	);

	const verifyPasscodeStatus = useSelector(
		(state: any) => state.worker.verifyPasscode.status
	);

	const verifyPasscodeError = useSelector(
		(state: any) => state.worker.verifyPasscode.errorMessage
	);

	const verifyPasscodeIsLocked = useSelector(
		(state: any) => state.worker.verifyPasscode.isLocked
	);

	const authenticated = useSelector((state: any) => state.worker.authenticated);

	const refreshStatus = useSelector(
		(state: any) => state.worker.Refresh.status
	);

	const getSkillLevelsStatus = useSelector(
		(state: any) => state.worker.getSkillLevels.status
	);

	const getCustomLogoStatus = useSelector(
		(state: any) => state.worker.getCustomLogo.status
	);

	const customLogo = useSelector(
		(state: any) => state.worker.customLogo
	);

	const getSettingsStatus = useSelector(
		(state: any) => state.worker.getSettings.status
	);

	const settings = useSelector(
		(state: any) => state.worker.settings
	);

	const settingsInitialized = useSelector(
		(state: any) => state.worker.settings.initialized
	);

	const customizedBrandingEnabled = useSelector(
		(state: any) => state.worker.settings.customizedBrandingEnabled
	);

	const getCustomLogoResponse = useSelector(
		(state: any) => state.worker.getCustomLogo.response
	);

	const showSkill = useSelector(
		(state: any) => state.worker.settings.showSkill
	);

	const regularShifts = useSelector(
		(state: any) => state.worker.regularShifts
	);

	const getRegularShiftsStatus = useSelector(
		(state: any) => state.worker.getRegularShifts.status
	);

	const workerType = useSelector(
		(state: any) => state.worker.workerType
	);

	const regularShift = useSelector(
		(state: any) => state.worker.viewRegularShiftDialog.shift
	);

	const canLoadMoreUpcomingShifts = useSelector(
		(state: any) => state.worker.getUpcomingShifts.canLoadMore
	);

	const canLoadMoreConfirmedShifts = useSelector(
		(state: any) => state.worker.getConfirmedShifts.canLoadMore
	);

	const canLoadMoreRegularShifts = useSelector(
		(state: any) => state.worker.getRegularShifts.canLoadMore
	);

	const viewShiftCanSwap = useSelector((state: any) => !state.worker.viewShiftDialog.swap);

	const viewShiftStatus = useSelector(
		(state: any) => state.worker.viewShiftDialog.status
	);

	const weeklyHoursLimitMessage = useSelector(
		(state: any) => state.worker.confirmShift.weeklyHoursLimitMessage,
	);

	const confirmShiftConsecutiveShifts = useSelector(
		(state: any) => state.worker.confirmShift.shifts,
	);

	const signupShiftCanSwap = useSelector(
		(state: any) => state.worker.signupShiftDialog.swap
	);

	const signupShiftSwapId = useSelector(
		(state: any) => state.worker.signupShiftDialog.swapId
	);

	const acknowledgements = useSelector(
		(state: any) => state.worker.acknowledgements
	);

	const acknowledgementsStatus = useSelector(
		(state: any) => state.worker.acknowledgements.status
	);

	const message = useSelector((state: any) => state.worker?.cancelShift?.response?.message);
	const details = useSelector((state: any) => state.worker?.cancelShift?.response?.details);

	const reduxForceCancelShift = useSelector(
		(state: any) => state.worker.forceCancelShift
	);

	const getCancelShiftReasonsStatus = useSelector(
		(state: any) => state.worker?.getCancelShiftReasons?.status
	);

	return {
		refreshStatus,
		authenticated,
		verifyPasscodeError,
		verifyPasscodeStatus,
		verifyPasscodeIsLocked,
		getPasscodeError,
		getPasscodeStatus,
		upcomingShiftsStatus,
		isSignupShiftDialogOpen,
		confirmedShiftsStatus,
		isCancelShiftPageOpen,
		isAcknowledgementPageOpen,
		isViewShiftsDialogOpen,
		isViewRegularShiftsDialogOpen,
		confirmedShiftsErrorMessage,
		getUpcomingShiftsErrorMessage,
		upcomingShifts,
		shiftsConfirmed,
		location,
		confirmShiftStatus,
		allShiftsConfirmed,
		skillLevels,
		shiftId,
		reduxCancelShift,
		reduxForceCancelShift,
		signupShiftId,
		confirmShiftErrorMessage,
		declineShiftStatus,
		declineShiftErrorMessage,
		allUpcomingShifts,
		cancelShiftStatus,
		confirmedShifts,
		getCancelShiftReasonsStatus,
		getSkillLevelsStatus,
		customLogo,
		getCustomLogoStatus,
		getCustomLogoResponse,
		getSettingsStatus,
		regularShifts,
		settings,
		settingsInitialized,
		customizedBrandingEnabled,
		showSkill,
		getRegularShiftsStatus,
		workerType,
		regularShift,
		canLoadMoreUpcomingShifts,
		canLoadMoreConfirmedShifts,
		canLoadMoreRegularShifts,
		viewShiftCanSwap,
		viewShiftStatus,
		weeklyHoursLimitMessage,
		confirmShiftConsecutiveShifts,
		signupShiftCanSwap,
		signupShiftSwapId,
		acknowledgements,
		acknowledgementsStatus,
		message,
		details,
	};
};

export default useWebWorkerData;
